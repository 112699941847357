/*
 * Licensed under the EUPL, Version 1.2 or – as soon they will be approved by
 * the European Commission - subsequent versions of the EUPL (the "Licence");
 * You may not use this work except in compliance with the Licence.
 * You may obtain a copy of the Licence at:
 *
 *   https://joinup.ec.europa.eu/software/page/eupl
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the Licence is distributed on an "AS IS" basis,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the Licence for the specific language governing permissions and
 * limitations under the Licence.
 *
 */

export const SELECT_SUPPLIER = 'SELECT_SUPPLIER';

export const REQUESTED_SUPPLIER_STATUS = 'REQUESTED_SUPPLIER_STATUS';
export const RECEIVED_SUPPLIER_STATUS = 'RECEIVED_SUPPLIER_STATUS';
export const ERROR_SUPPLIER_STATUS = 'ERROR_SUPPLIER_STATUS';

export const REQUESTED_SUPPLIERS = 'REQUESTED_SUPPLIERS';
export const RECEIVED_SUPPLIERS = 'RECEIVED_SUPPLIERS';
export const ERROR_SUPPLIERS = 'ERROR_SUPPLIERS';

export const ERROR_IMPORT_DATA = 'ERROR_IMPORT_DATA';
export const REQUEST_IMPORT_DATA = 'REQUEST_IMPORT_DATA';
export const SUCCESS_IMPORT_DATA = 'SUCCESS_IMPORT_DATA';

export const ERROR_EXPORT_DATA = 'ERROR_EXPORT_DATA';
export const REQUEST_EXPORT_DATA = 'REQUEST_EXPORT_DATA';
export const SUCCESS_EXPORT_DATA = 'SUCCESS_EXPORT_DATA';

export const ERROR_DELETE_DATA = 'ERROR_DELETE_DATA';
export const REQUEST_DELETE_DATA = 'REQUEST_DELETE_DATA';
export const SUCCESS_DELETE_DATA = 'SUCCESS_DELETE_DATA';

export const ERROR_BUILD_GRAPH = 'ERROR_BUILD_GRAPH';
export const REQUEST_BUILD_GRAPH = 'REQUEST_BUILD_GRAPH';
export const SUCCESS_BUILD_GRAPH = 'SUCCESS_BUILD_GRAPH';

export const ERROR_BUILD_BASE_GRAPH = 'ERROR_BUILD_BASE_GRAPH';
export const REQUEST_BUILD_BASE_GRAPH = 'REQUEST_BUILD_BASE_GRAPH';
export const SUCCESS_BUILD_BASE_GRAPH = 'SUCCESS_BUILD_BASE_GRAPH';

export const ERROR_BUILD_CANDIDATE_GRAPH_OTP2 =
  'ERROR_BUILD_CANDIDATE_GRAPH_OTP2';
export const REQUEST_BUILD_CANDIDATE_GRAPH_OTP2 =
  'REQUEST_BUILD_CANDIDATE_GRAPH_OTP2';
export const SUCCESS_BUILD_CANDIDATE_GRAPH_OTP2 =
  'SUCCESS_BUILD_CANDIDATE_GRAPH_OTP2';

export const ERROR_BUILD_CANDIDATE_BASE_GRAPH_OTP2 =
  'ERROR_BUILD_CANDIDATE_BASE_GRAPH_OTP2';
export const REQUEST_BUILD_CANDIDATE_BASE_GRAPH_OTP2 =
  'REQUEST_BUILD_CANDIDATE_BASE_GRAPH_OTP2';
export const SUCCESS_BUILD_CANDIDATE_BASE_GRAPH_OTP2 =
  'SUCCESS_BUILD_CANDIDATE_BASE_GRAPH_OTP2';

export const ERROR_FETCH_OSM = 'ERROR_FETCH_OSM';
export const REQUEST_FETCH_OSM = 'REQUEST_FETCH_OSM';
export const SUCCESS_FETCH_OSM = 'SUCCESS_FETCH_OSM';

export const ERROR_UPLOAD_GOOGLE_PRODUCTION = 'ERROR_UPLOAD_GOOGLE_PRODUCTION';
export const REQUEST_UPLOAD_GOOGLE_PRODUCTION =
  'REQUEST_UPLOAD_GOOGLE_PRODUCTION';
export const SUCCESS_UPLOAD_GOOGLE_PRODUCTION =
  'SUCCESS_UPLOAD_GOOGLE_PRODUCTION';

export const ERROR_UPLOAD_GOOGLE_QA = 'ERROR_UPLOAD_GOOGLE_QA';
export const REQUEST_UPLOAD_GOOGLE_QA = 'REQUEST_UPLOAD_GOOGLE_QA';
export const SUCCESS_UPLOAD_GOOGLE_QA = 'SUCCESS_UPLOAD_GOOGLE_QA';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';

export const REQUEST_FILENAMES = 'REQUEST_FILENAMES';
export const SUCCESS_FILENAMES = 'SUCCESS_FILENAMES';
export const ERROR_FILENAMES = 'ERROR_FILENAMES';

export const SUCCESS_FETCH_PROVIDER = 'SUCCESS_FETCH_PROVIDER';
export const ERROR_FETCH_PROVIDER = 'ERROR_FETCH_PROVIDER';

export const SUCCESS_CREATE_PROVIDER = 'SUCCESS_CREATE_PROVIDER';
export const ERROR_CREATE_PROVIDER = 'ERROR_CREATE_PROVIDER';

export const SUCCESS_UPDATE_PROVIDER = 'SUCCESS_UPDATE_PROVIDER';
export const ERROR_UPDATE_PROVIDER = 'ERROR_UPDATE_PROVIDER';

export const SUCCESS_DELETE_PROVIDER = 'SUCCESS_DELETE_PROVIDER';
export const ERROR_DELETE_PROVIDER = 'ERROR_DELETE_PROVIDER';

export const SUCCESS_VALIDATE_PROVIDER = 'SUCCESS_VALIDATE_PROVIDER';
export const ERROR_VALIDATE_PROVIDER = 'ERROR_VALIDATE_PROVIDER';

export const APPEND_FILES_TO_OUTBOUND = 'APPEND_FILES_TO_OUTBOUND';
export const REMOVE_FILES_FROM_OUTBOUND = 'REMOVE_FILES_FROM_OUTBOUND';
export const RESET_OUTBOUND_FILES = 'RESET_OUTBOUND_FILES';
export const UPDATE_FILES_TO_OUTBOUND = 'UPDATE_FILES_TO_OUTBOUND';

export const TOGGLE_EXPANDABLE_FOR_EVENT_WRAPPER =
  'TOGGLE_VISIBILITY_FOR_EVENT_WRAPPER';

export const SUCCESS_CHOUETTE_JOB_STATUS = 'SUCCESS_CHOUETTE_JOB_STATUS';
export const ERROR_CHOUETTE_JOB_STATUS = 'ERROR_CHOUETTE_JOB_STATUS';

export const OPENED_HISTORY_MODAL = 'OPENED_HISTORY_MODAL';
export const DISMISS_MODAL_DIALOG = 'DISMISS_MODAL_DIALOG';
export const OPENED_EDIT_PROVIDER_DIALOG = 'OPENED_EDIT_PROVIDER_DIALOG';
export const OPENED_NEW_PROVIDER_DIALOG = 'OPENED_NEW_PROVIDER_DIALOG';
export const OPENED_POI_FILTER_DIALOG = 'OPENED_POI_FILTER_DIALOG';

export const DISMISS_EDIT_PROVIDER_DIALOG = 'DISMISS_EDIT_PROVIDER_DIALOG';

export const LOG_EVENT = 'LOG_EVENT';

export const LOG_EVENT_FILTER = 'LOG_EVENT_FILTER';

export const SUCCESS_DELETE_PROVIDERS_CHOUETTE_JOB =
  'SUCCESS_DELETE_PROVIDERS_CHOUETTE_JOB';
export const ERROR_DELETE_PROVIDERS_CHOUETTE_JOB =
  'ERROR_DELETE_PROVIDERS_CHOUETTE_JOB';
export const SUCCESS_DELETE_ALL_PROVIDERS_CHOUETTE_JOB =
  'SUCCESS_DELETE_ALL_PROVIDERS_CHOUETTE_JOB';
export const ERROR_DELETE_ALL_PROVIDERS_CHOUETTE_JOB =
  'ERROR_DELETE_ALL_PROVIDERS_CHOUETTE_JOB';

export const RECEIVED_TRANSPORT_MODES = 'RECEIVED_TRANSPORT_MODES';

export const TOGGLE_CHOUETTE_INFO_CHECKBOX_FILTER =
  'TOGGLE_CHOUETTE_INFO_CHECKBOX_FILTER';
export const TOGGLE_CHOUETTE_INFO_CHECKBOX_ALL_FILTER =
  'TOGGLE_CHOUETTE_INFO_CHECKBOX_ALL_FILTER';

export const SET_ACTIVE_ACTION_FILTER = 'SET_ACTIVE_ACTION_FILTER';
export const SET_ACTIVE_ACTION_ALL_FILTER = 'SET_ACTIVE_ACTION_ALL_FILTER';

export const UNSELECTED_ALL_SUPPLIERS = 'UNSELECTED_ALL_SUPPLIERS';

export const SELECTED_ALL_SUPPLIERS = 'SELECTED_ALL_SUPPLIERS';

export const SUCCESS_ALL_CHOUETTE_JOB_STATUS =
  'SUCCESS_ALL_CHOUETTE_JOB_STATUS';
export const ERROR_ALL_CHOUETTE_JOB_STATUS = 'ERROR_ALL_CHOUETTE_JOB_STATUS';

export const SORT_EVENTLIST_BY_COLUMN = 'SORT_EVENTLIST_BY_COLUMN';
export const SORT_CHOUETTE_ALL_BY_COLUMN = 'SORT_CHOUETTE_ALL_BY_COLUMN';
export const SORT_CHOUETTE_BY_COLUMN = 'SORT_CHOUETTE_BY_COLUMN';

export const REQUESTED_ALL_CHOUETTE_JOB_STATUS =
  'REQUESTED_ALL_CHOUETTE_JOB_STATUS';
export const REQUESTED_CHOUETTE_JOBS_FOR_PROVIDER =
  'REQUESTED_CHOUETTE_JOBS_FOR_PROVIDER';

export const UPDATED_SUPPLIER_FORM = 'UPDATED_SUPPLIER_FORM';

export const REQUEST_TRANSFER_DATA = 'REQUEST_TRANSFER_DATA';
export const SUCCESS_TRANSFER_DATA = 'SUCCESS_TRANSFER_DATA';
export const ERROR_TRANSFER_DATA = 'ERROR_TRANSFER_DATA';

export const RECEIVED_GRAPH_STATUS = 'RECEIVED_GRAPH_STATUS';
export const RECEIVED_GRAPH_VERSIONS = 'RECEIVED_GRAPH_VERSIONS';

export const ERRORS_SUPPLIERS = 'ERRORS_SUPPLIERS';

export const REQUESTED_ALL_SUPPLIERS_STATUS = 'REQUESTED_ALL_SUPPLIERS_STATUS';
export const RECEIVED_ALL_SUPPLIERS_STATUS = 'RECEIVED_ALL_SUPPLIERS_STATUS';

export const UPDATED_TARIFF_ZONE_FILE_UPLOAD_PROGRESS =
  'UPDATED_TARIFF_ZONE_FILE_UPLOAD_PROGRESS';

/* organization actions */

export const RECEIVED_ROLES = 'RECEIVED_ROLES';
export const CREATED_ROLE = 'CREATED_ROLE';
export const FAILED_CREATING_ROLE = 'FAILED_CREATING_ROLE';

export const RECEIVED_ORGANIZATIONS = 'RECEIVED_ORGANIZATIONS';
export const CREATED_ORGANIZATION = 'CREATED_ORGANIZATION';
export const FAILED_CREATING_ORGANIZATION = 'FAILED_CREATING_ORGANIZATION';
export const UPDATED_ORGANIZATION = 'UPDATED_ORGANIZATION';

export const RECEIVED_CODESPACES = 'RECEIVED_CODESPACES';

export const RECEIVED_USERS = 'RECEIVED_USERS';
export const RECEIVED_RESPONSIBILITES = 'RECEIVED_RESPONSIBILITES';

export const CREATED_RESPONSIBILITY_SET = 'CREATED_RESPONSIBILITY_SET';
export const UPDATED_RESPONSIBILITY_SET = 'UPDATED_RESPONSIBILITY_SET';

export const RECEIVED_ENTITY_TYPES = 'RECEIVED_ENTITY_TYPES';

export const CREATED_USER = 'CREATED_USER';
export const FAILED_CREATING_USER = 'FAILED_CREATING_USER';

export const CREATED_ENTITY_TYPE = 'CREATED_ENTITY_TYPE';
export const FAILED_CREATING_ENTITY_TYPE = 'FAILED_CREATING_ENTITY_TYPE';
export const UPDATED_ENTITY_TYPE = 'UPDATED_ENTITY_TYPE';
export const UPDATED_USER = 'UPDATED_USER';
export const RECEIVED_USER_NOTIFICATIONS = 'RECEIVED_USER_NOTIFICATIONS';
export const ENABLED_USER_NOTIFICATION = 'ENABLED_USER_NOTIFICATION';
export const RECEIVED_EVENT_FILTER_TYPES = 'RECEIVED_EVENT_FILTER_TYPES';
export const RECEIVED_JOB_DOMAINS = 'RECEIVED_JOB_DOMAINS';
export const CHANGED_EVENT_FILTER_TYPE = 'CHANGED_EVENT_FILTER_TYPE';
export const RECEIVED_JOB_ACTIONS_FOR_DOMAIN =
  'RECEIVED_JOB_ACTIONS_FOR_DOMAIN';
export const CHANGED_EVENT_FILTER_JOB_DOMAIN =
  'CHANGED_EVENT_FILTER_JOB_DOMAIN';
export const CHANGE_EVENT_FILTER_ACTION = 'CHANGE_EVENT_FILTER_ACTION';
export const RECEIVED_EVENT_FILTER_STATES = 'RECEIVED_EVENT_FILTER_STATES';
export const CHANGE_EVENT_FILTER_STATE = 'CHANGE_EVENT_FILTER_STATE';
export const CHANGED_EVENT_FILTER_ORG_REF = 'CHANGED_EVENT_FILTER_ORG_REF';
export const CHANGED_NOTIFICATION_TYPE = 'CHANGED_NOTIFICATION_TYPE';
export const RECEIVED_ADMINISTRATIVE_ZONES = 'RECEIVED_ADMINISTRATIVE_ZONES';
export const RECEIVED_NOTIFICATION_TYPES = 'RECEIVED_NOTIFICATION_TYPES';
export const ADDED_ADMIN_ZONE_REF = 'ADDED_ADMIN_ZONE_REF';
export const REMOVED_ADMIN_ZONE_REF = 'REMOVED_ADMIN_ZONE_REF';
export const ADDED_ENTITY_CLASS_REF = 'ADDED_ENTITY_CLASS_REF';
export const REMOVED_ENTITY_CLASS_REF = 'REMOVED_ENTITY_CLASS_REF';
export const DELETED_USER_NOTIFICATION = 'DELETED_USER_NOTIFICATION';
export const ADDED_NEW_USER_NOTIFICATION = 'ADDED_NEW_USER_NOTIFICATION';
export const REQUESTED_USER_NOTIFICATION = 'REQUESTED_USER_NOTIFICATION';
export const UPDATED_NOTIFICATION_CONFIGURATION =
  'UPDATED_NOTIFICATION_CONFIGURATION';

export const OPENED_NEW_PASSWORD_DIALOG = 'OPENED_NEW_PASSWORD_DIALOG';
export const CLOSED_NEW_PASSWORD_DIALOG = 'CLOSED_NEW_PASSWORD_DIALOG';

export const REQUESTED_EXPORTED_FILES = 'REQUESTED_EXPORTED_FILES';
export const RECEIVED_EXPORTED_FILES = 'RECEIVED_EXPORTED_FILES';

/* utils actions */
export const CONFIG_LOADED = 'CONFIG_LOADED';

export const TOGGLE_MENU = 'TOGGLE_MENU';

export const RECEIVED_USER_CONTEXT = 'RECEIVED_USER_CONTEXT';
export const UPDATE_AUTH = 'UPDATE_AUTH';
